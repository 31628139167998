<template>
  <div>
    <div class="booking-calendar-panel">
      <div class="booking-calendar-heading">
        <div class="filter-date">
          <input type="date" class="input-date" :defaultValue="selectedDate" v-on:change="updateSelectedDate" />
          <button class="btn-nav btn-prev bg-btn-radius" v-on:click="decrementDate">
            <ArrowIcon direction="left" size="18px" fillColor=styles?.prevButtonColor />
          </button>
          <button class="btn-nav btn-next bg-btn-radius" v-on:click="incrementDate">
            <ArrowIcon direction="right" size="18px" fillColor=styles?.nextButtonColor />
          </button>
        </div>

        <!-- <button class="btn-reservation" @click="toggleModal">Create
          Reservation</button> -->
      </div>
      <div class="booking-calendar-body">
        <div class="items-container">
          <!-- Heading row to show dates and month -->
          <div class="calendar-row rowspan-2">
            <div class="calendar-cell label"></div>
            <div class="months" v-for="month in months" :key="month.name">
              <div class="caption">{{ month.name }}</div>
              <div class="dates">
                <div :class="['calendar-cell', 'date', {
                  'selected': selectedDate === date.format('YYYY-MM-DD')
                }, { 'current': date.format('YYYY-MM-DD') === currentDate }]"
                  :style="[{ 'min-width': `${cellWidth}px` }, { background: styles?.cellBackground }, selectedDate === date.format('YYYY-MM-DD') ? { background: `${styles.selectedDateBackground}` } : (date.format('YYYY-MM-DD') === currentDate ? { background: styles.currentDateBackground, color: styles.currentDateColor } : {})]"
                  v-for="date in month.dates" :key="date">
                  {{ date.format("ddd, D") }}
                </div>
              </div>
            </div>
          </div>

          <!-- Groups row to resources and group -->
          <div :class="`item-group ${resource.expanded && 'expanded'}`" v-for="resource in resourcesLocal"
            :key="resource.id">
            <div class="calendar-row" :style="`height: ${rowHeight}px`">
              <button class="calendar-cell label btn-expand" :style="[styles.resourceLabelParentStyles]"
                v-on:click="toggleExpanded(resource.id)">
                <span style="margin-right: 5px;">{{ resource.name }}</span>
                <ArrowIcon direction="right" size="10px" />
              </button>
              <div class="months" v-for="month in months" :key="month.name">
                <div class="dates">
                  <!-- date cells -->
                  <div :class="['calendar-cell', 'non-selectable', 'date', { selected: selectedDate === date.format('YYYY-MM-DD') }, { 'current': date.format('YYYY-MM-DD') === currentDate },
                    { 'sat': date.format('ddd') === 'Sat' }, { 'sun': date.format('ddd') === 'Sun' }]"
                    :style="[{ minWidth: `${cellWidth}px` }, { background: styles?.cellBackground }, selectedDate === date.format('YYYY-MM-DD') ? { background: `${styles.selectedDateBackground}` } : (date.format('YYYY-MM-DD') === currentDate ? { background: styles.currentDateBackground, color: styles.currentDateColor } : {})]"
                    v-for="date in month.dates" :key="date">
                  </div>
                </div>
              </div>
            </div>

            <!-- Resource childrens -->
            <div class="childs-container" v-if="resource.expanded">
              <div class="calendar-row" :style="`height: ${rowHeight}px`" v-for="child in resource.children"
                :key="child.id">
                <div class="calendar-cell label" :style="[styles.resourceLabelChildStyles]">
                  {{ child.name }}
                </div>
                <div class="months" v-for="month in months" :key="month.name">
                  <div class="dates">
                    <div
                      :class="['calendar-cell', 'date',
                        { 'selected': selectedDate === date.format('YYYY-MM-DD') }, { 'current': date.format('YYYY-MM-DD') === currentDate },
                        { 'sat': date.format('ddd') === 'Sat' }, { 'sun': date.format('ddd') === 'Sun' }, { 'blocked-cell': isOffDate(child.id, date) }]"
                      :style="[{ minWidth: `${cellWidth}px` }, { background: styles?.cellBackground }, selectedDate === date.format('YYYY-MM-DD') ? { background: `${styles.selectedDateBackground}` } : (date.format('YYYY-MM-DD') === currentDate ? { background: styles.currentDateBackground, color: styles.currentDateColor } : {})]"
                      v-for="date in month.dates" :id="`selectable-cell-${date.format('YYYY-MM-DD')}`"
                      :key="`selectable-cell-${date.format('YYYY-MM-DD')}`">

                      <!-- hours breakdown -->
                      <div :class="`hour ${isBooked(child.id, date, hour) && 'booked-hour'} ${isBooked(child.id, date,
                        hour) && (isBookedStartDate(child.id, date, hour) || isBookedEndDate(child.id, date, hour))
                        && 'resizable'}`"
                        :style="[isBooked(child.id, date, hour) && styles.eventBackground ? { background: styles.eventBackground } : {}, isBooked(child.id, date, hour) && isBookedStartDate(child.id, date, hour) ? { background: `${styles?.eventStartBackground} !important` } : {}, isBooked(child.id, date, hour) && isBookedEndDate(child.id, date, hour) ? { background: `${styles?.eventEndBackground} !important` } : {}]"
                        v-for="hour in hours" :key="`selectable-hour-slot-${date.hour(hour).format('YYYY-MM-DD HH')}`"
                        @mousedown.prevent="startSelection($event, child, date, hour)"
                        @mouseover.prevent="extendSelection($event, date, hour)"
                        @mouseup.prevent="endSelection(date, hour)">

                        <div
                          :style="`position: absolute; width:${cellWidth / 24}px; height: ${rowHeight}px; pointer-events: none; z-index: 2;`"
                          :id="`selectable-hour-slot-${child.id}-${date.hour(hour).format('YYYY-MM-DD HH')}`">
                          <span class="blocked-selection-btn">X</span>
                        </div>

                        <div v-html="getEventDiv(child.id, date, hour)"></div>
                        <button v-if="isBooked(child.id, date, hour) && isBookedEndDate(child.id, date, hour)"
                          class="btn-delete-event"
                          @mouseup.prevent="deleteEvent($event, child.id, date, hour)">X</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div v-if="isModalVisible" class="modal fade show" id="exampleModal" tabindex="-1"
      aria-labelledby="exampleModalLabel" aria-hidden="true" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
            <form>
              <div class="mb-3">
                <label for="eventName" class="form-label">{{ this.isUpdating ? 'Update Booking' : 'Book Event'
                  }}</label>
                <input type="text" class="form-control" id="eventName" placeholder="Enter event name"
                  v-model="newEventTitle" @change="updateEventName">
              </div>
              <div class="d-flex gap-2">
                <div class="mb-2">
                  <label for="startDate" class="form-label">Start Date</label>
                  <input type="datetime-local" class="form-control input-font" id="startDate"
                    :defaultValue="selectionStart?.date.format('YYYY-MM-DD HH:mm')" @change="updateEventStartDate">
                </div>
                <div class="mb-2">
                  <label for="endDate" class="form-label">End Date</label>
                  <input type="datetime-local" class="form-control input-font" id="endDate"
                    :defaultValue="selectionEnd?.date.format('YYYY-MM-DD HH:mm')" @change="updateEventEndDate">
                </div>
              </div>
            </form>
            <div class="footer-modal">
              <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
              <button type="button" class="btn save-event-btn" @click="createEvent">{{ isUpdating ? 'Update Booking' :
                'Book Event' }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowIcon from "./ArrowIcon.vue";
import moment from "moment";

export default {
  name: "BookingCalendar",
  components: {
    ArrowIcon,
  },
  props: {
    resources: {
      type: Array,
      required: true,
    },
    events: {
      type: Array
    },
    styles: {
      type: Object
    },
    offDates: {
      type: Object
    }
  },
  data() {

    return {
      selectedDate: moment().format("YYYY-MM-DD"),
      currentDate: moment().format('YYYY-MM-DD'),
      previousDatesCount: 3,
      upcomingDatesCount: 15,
      hours: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      isSelecting: false,
      isUpdating: false,
      updatingEventDetails: null,
      selectionStart: null,
      selectionEnd: null,
      newEventTitle: 'Event 1',
      isModalVisible: false,
      resourcesLocal: this.resources,
      cellWidth: window.innerWidth / 16 > this.styles?.cellWidth ? window.innerWidth / 16 : this.styles?.cellWidth || (window.innerWidth / 16 > 80 ? window.innerWidth / 16 : 80),
      rowHeight: this.styles.rowHeight || 30,
    };
  },
  methods: {
    incrementDate() {
      this.selectedDate = moment(this.selectedDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    },

    decrementDate() {
      this.selectedDate = moment(this.selectedDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    },

    updateSelectedDate(e) {
      this.selectedDate = moment(e.target.value).format("YYYY-MM-DD");
    },

    toggleExpanded(resourceId) {
      const resource = this.resourcesLocal.find(res => res.id === resourceId);
      if (resource) {
        resource.expanded = !resource.expanded;
      }
    },

    toggleModal() {
      this.isModalVisible = !this.isModalVisible
    },

    isOffDate(resourceId, date) {
      const comparableInputDate = moment(date).format('YYYY-MM-DD').valueOf()

      //if it is past date then also consider it as off date
      if (comparableInputDate < moment().format('YYYY-MM-DD').valueOf()) {
        return true;
      }
      // check if blocked for particular resource or not
      if (this.offDates.resources[resourceId]) {
        for (let obj of this.offDates.resources[resourceId]) {
          const comparableStartDate = moment(obj.start_date).format('YYYY-MM-DD').valueOf()
          const comparableEndDate = moment(obj.end_date).format('YYYY-MM-DD').valueOf()
          if (comparableInputDate >= comparableStartDate && comparableInputDate <= comparableEndDate) {
            return true;
          }
        }
      }

      //check if date blocked globally
      for (let obj of this.offDates.global) {
        const comparableStartDate = moment(obj.start_date).format('YYYY-MM-DD').valueOf()
        const comparableEndDate = moment(obj.end_date).format('YYYY-MM-DD').valueOf()
        if (comparableInputDate >= comparableStartDate && comparableInputDate <= comparableEndDate) {
          return true;
        }
      }

      return false;
    },

    getDatesInRange(startDate, endDate) {
      const dates = [];
      let currentDate = moment(startDate);

      while (currentDate.format('YYYY-MM-DD').valueOf() <= moment(endDate).format('YYYY-MM-DD').valueOf()) {
        dates.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'days');
      }
      return dates;
    }
    ,

    containsOffDate(resourceId, startDate, endDate) {
      const comparableCurrentDate = moment().format('YYYY-MM-DD').valueOf();
      const comparableStartDate = moment(startDate).format('YYYY-MM-DD').valueOf()
      const comparableEndDate = moment(endDate).format('YYYY-MM-DD').valueOf()

      if (comparableStartDate > comparableEndDate) {
        let temp = startDate;
        startDate = endDate;
        endDate = temp;
      }

      //considering past dates as also off dates
      if (comparableStartDate < comparableCurrentDate) {
        return true;
      }


      const dateRangeSelected = this.getDatesInRange(startDate, endDate)

      //check if blocked for specific resource or not
      if (this.offDates.resources[resourceId]) {
        for (let obj of this.offDates.resources[resourceId]) {
          for (let date of dateRangeSelected) {
            const comparableInputDate = moment(date).format('YYYY-MM-DD').valueOf()
            const comparableStartDate = moment(obj.start_date).format('YYYY-MM-DD').valueOf()
            const comparableEndDate = moment(obj.end_date).format('YYYY-MM-DD').valueOf()
            if (comparableInputDate >= comparableStartDate && comparableInputDate <= comparableEndDate) {
              return true;
            }
          }
        }
      }

      //check if blocked globally
      for (let obj of this.offDates.global) {
        for (let date of dateRangeSelected) {
          const comparableInputDate = moment(date).format('YYYY-MM-DD').valueOf()
          const comparableStartDate = moment(obj.start_date).format('YYYY-MM-DD').valueOf()
          const comparableEndDate = moment(obj.end_date).format('YYYY-MM-DD').valueOf()
          if (comparableInputDate >= comparableStartDate && comparableInputDate <= comparableEndDate) {
            return true;
          }
        }
      }

      return false;
    },

    getEventDetails(resourceId, date, hour) {
      const inputDate = moment(date).hour(hour).format('YYYY-MM-DD HH').valueOf()
      const index = this.events.findIndex((event) => event.resource.id == resourceId && moment(event.start).format('YYYY-MM-DD HH').valueOf() <= inputDate && moment(event.end).format('YYYY-MM-DD HH').valueOf() >= inputDate)

      return index !== -1 ? this.events[index] : null
    },

    isBooked(resourceId, date, hour) {
      const inputDate = moment(date).hour(hour).format('YYYY-MM-DD HH').valueOf()
      const index = this.events.findIndex((event) => event.resource.id == resourceId && moment(event.start).format('YYYY-MM-DD HH').valueOf() <= inputDate && moment(event.end).format('YYYY-MM-DD HH').valueOf() >= inputDate)
      return index !== -1
    },

    isDateBooked(resourceId, date) {
      let result = false;
      for (let hour = 0; hour <= 23; hour++) {
        if (this.isBooked(resourceId, date, hour)) {
          result = true;
          break;
        }
      }
      return result;
    },

    isBookedStartDate(resourceId, date, hour) {
      const inputDate = moment(date).hour(hour).format('YYYY-MM-DD HH')
      let flag = false;
      for (const event of this.events) {
        if (event.resource.id === resourceId && moment(event.start).format('YYYY-MM-DD HH') === inputDate) {
          flag = true;
        }
      }
      return flag
    },

    isBookedEndDate(resourceId, date, hour) {
      const inputDate = moment(date).hour(hour).format('YYYY-MM-DD HH')
      let flag = false;
      for (const event of this.events) {
        if (event.resource.id === resourceId && moment(event.end).format('YYYY-MM-DD HH') === inputDate) {
          flag = true;
        }
      }
      return flag;
    },

    startSelection(event, room, date, hour) {
      this.cleanSelection()

      if (this.isBooked(room.id, date, hour) && !this.isBookedStartDate(room.id, date, hour) && !this.isBookedEndDate(room.id, date, hour)) {
        // do not start selecting if user clicks in middle of the booked event
        const eventDetails = this.getEventDetails(room.id, date, hour)
        return this.$emit('event-clicked', eventDetails)
      }

      // if (moment(date).valueOf() < moment().valueOf()) {
      //   // make sure not to start selection from past dates
      //   alert('Cannot book events for past dates')
      //   return;
      // }

      this.isSelecting = true;
      const initializedHour = {}
      initializedHour.div = document.getElementById(`selectable-hour-slot-${room.id}-${moment(date).hour(hour).format('YYYY-MM-DD HH')}`)
      initializedHour.initialWidth = (document.getElementById(`selectable-hour-slot-${room.id}-${moment(date).hour(hour).format('YYYY-MM-DD HH')}`).style.width).split('px')[0]

      initializedHour.div.style.background = !this.isOffDate(room.id, date) ? (this.styles?.draggingCellBackground ?? '#b3d4fc') : '#FA8072'


      this.selectionStart = { event, initializedHour, room, date: moment(date).hour(hour) };
      this.selectionEnd = { event, room, date: moment(date).hour(hour) };
      const eventDetails = this.getEventDetails(room.id, date, hour);

      if (this.isBooked(room.id, date, hour) && this.isBookedEndDate(room.id, date, hour)) {
        //if event is already booked and current pointer is on endDate/time, then update startDate to existing events startDate
        this.selectionStart = { event, initializedHour, room, date: moment(eventDetails.start) }
        this.isUpdating = true;
        this.updatingEventDetails = { startPosition: 'back', eventDetails };
      } else if (this.isBooked(room.id, date, hour) && this.isBookedStartDate(room.id, date, hour)) {
        //if event is already booked and current pointer is on startDate/time, then update endDate to existing events endDate

        this.selectionEnd = { event, room, date: moment(eventDetails.end) }
        this.isUpdating = true;
        this.updatingEventDetails = { startPosition: 'front', eventDetails }
      }
    },

    extendSelection(event, date, hour) {
      if (this.isSelecting) {
        if (!this.isUpdating && this.isBooked(this.selectionStart.room.id, date, hour)) {
          //Ensure that when creating the event through dragging, it does not overlap with already booked events
          window.alert('Cannot overlap with exising event')
          this.cleanSelection()
          return;
        }

        if (this.isUpdating && this.isBooked(this.selectionStart.room.id, date, hour)) {
          //check if event being updated is not same as booked event on which mouse is currently pointing
          const eventDetails = this.getEventDetails(this.selectionStart.room.id, date, hour);
          if (eventDetails.id !== this.updatingEventDetails.eventDetails.id) {
            window.alert('Cannot overlap with exising event')
            this.cleanSelection()
            return;
          }
        }

        //check if date is blocked 
        if (this.containsOffDate(this.selectionStart.room.id, this.selectionStart.date, date)) {
          this.selectionStart.initializedHour.div.style.background = "#FA8072"
          this.selectionStart.initializedHour.div.children[0].style.visibility = "visible";
        } else {
          this.selectionStart.initializedHour.div.children[0].style.visibility = "hidden";
          this.selectionStart.initializedHour.div.style.background = "#b3d4fc";
        }

        // if (moment(date).format('YYYY-MM-DD HH').valueOf() < moment().format('YYYY-MM-DD HH').valueOf()) {
        //   //ensure to not select past dates
        //   window.alert('Cannot book events for past dates')
        //   this.cleanSelection()
        //   return;
        // }

        const startDate = moment(this.selectionStart.date)
        const currentDate = moment(date).hour(hour)

        const widthDifference = Math.abs(this.selectionStart.event.clientX - event.clientX)
        this.selectionStart.initializedHour.div.style.width = `${widthDifference}px`;

        if (currentDate.valueOf() < startDate.valueOf()) {
          this.selectionStart.initializedHour.div.style.left = `-${widthDifference}px`;
        }
        if (this.isUpdating) {
          const endDate = moment(this.selectionEnd.date);
          if (currentDate.valueOf() < endDate.valueOf() && this.updatingEventDetails.startPosition === 'back') {
            this.selectionStart.initializedHour.div.style.left = `-${widthDifference}px`;
          }
        }

      }
    },

    endSelection(date, hour) {
      if (this.isSelecting) {
        this.isSelecting = false;
        if (this.containsOffDate(this.selectionStart.room.id, this.selectionStart.date, date)) {
          //if the selection contains the off dates then do not book event
          this.cleanSelection()
          return;
        }

        if (this.isUpdating) {
          if (this.updatingEventDetails.startPosition === 'front') {
            // if decrementing date selection from existing date
            this.selectionStart.date = moment(date).hour(hour)

          } else {
            this.selectionEnd.date = moment(date).hour(hour)
          }
        } else {
          this.selectionEnd.date = moment(date).hour(hour)
        }

        const comparableStartDate = moment(this.selectionStart.date).format('YYYY-MM-DD HH').valueOf()
        const comparableEndDate = moment(this.selectionEnd.date).format('YYYY-MM-DD HH').valueOf()

        //make sure end date is greater than start date
        if (comparableStartDate > comparableEndDate) {
          const temp = this.selectionStart.date;
          this.selectionStart.date = this.selectionEnd.date;
          this.selectionEnd.date = temp;
        }

        // statically make startTime set to 12:00 PM and endTime set to 11:59 AM of the same day
        this.selectionStart.date = moment(this.selectionStart.date).set({ hour: 12, minute: 0 })
        this.selectionEnd.date = moment(this.selectionEnd.date).set({ hour: 11, minute: 59 })

        this.openBookingPopup(this.selectionStart, this.selectionEnd);
      }
    },

    cleanSelection() {
      this.isUpdating = false;
      this.updatingEventDetails = null;
      this.isSelecting = false;
      if (this.selectionStart) {
        this.selectionStart.initializedHour.div.style.width = this.cellWidth / 24 + 'px'
        this.selectionStart.initializedHour.div.style.left = 0;
        this.selectionStart.initializedHour.div.style.background = 'none';
        this.selectionStart.initializedHour.div.children[0].style.visibility = "hidden";
      }
      this.selectionStart = null;
      this.selectionEnd = null;
    },

    updateEventName(event) {
      this.newEventTitle = event.target.value
    },

    updateEventStartDate(event) {
      const date = event.target.value
      if (moment(date).valueOf() < moment().valueOf()) {
        window.alert('Cannot book event for past dates')
        event.target.value = moment(this.selectionStart.date).format('YYYY-MM-DD HH:MM')
        return;
      }
      if (moment(date).valueOf() > moment(this.selectionEnd.date).valueOf()) {
        // startDate should not be greater than endDate of evnet booking
        window.alert('Booking startDate cannot be greater than endDate')
        event.target.value = moment(this.selectionStart.date).format('YYYY-MM-DD HH:MM')
        return;
      }
      this.selectionStart.date = moment(date)
    },

    updateEventEndDate(event) {
      const date = event.target.value

      if (moment(date).valueOf() < moment(this.selectionStart.date).valueOf()) {
        event.target.value = moment(this.selectionEnd.date).format('YYYY-MM-DD HH:MM')
        window.alert('Booking endDate cannot be less than startDate')
        return;
      }
      this.selectionEnd.date = moment(date)
    },

    openBookingPopup(start, end) {
      // Logic to open booking popup with pre-filled dates
      // console.log(`Booking from ${start.date.format("YYYY-MM-DD HH")} to ${end.date.format("YYYY-MM-DD HH")} for room ${start.room.name}`);
      if (!start || !end) {
        return;
      }
      this.toggleModal()
    },

    createEvent() {
      // Handle saving changes logic here
      const event = {
        resource: { id: this.selectionStart.room.id, name: this.selectionStart.room.name },
        start: `${moment(this.selectionStart.date).format('YYYY-MM-DD HH:mm')}`,
        end: `${moment(this.selectionEnd.date).format('YYYY-MM-DD HH:mm')}`,
        text: this.newEventTitle
      }
      if (this.isUpdating) {
        event.id = this.updatingEventDetails.eventDetails.id;
        this.$emit('update-event', event)
        this.isUpdating = false;
        this.updatingEventDetails = null;
      } else {
        this.$emit('create-event', event)
      }
      //to close the modal after event is created
      this.closeModal()
    },

    deleteEvent(event, resourceId, date, hour) {
      event.stopPropagation();
      this.cleanSelection()
      const eventDetails = this.getEventDetails(resourceId, date, hour);
      if (!eventDetails) {
        return;
      }
      return this.$emit('delete-event', eventDetails)
    },

    closeModal() {
      this.cleanSelection()
      this.toggleModal()
    },

    getEventDiv(resourceId, date, hour) {
      if (!this.isBooked(resourceId, date, hour)) {
        return;
      }
      if (this.isBookedStartDate(resourceId, date, hour - 1)) {
        //this function should be called for booked events only
        const eventDetails = this.getEventDetails(resourceId, date, hour)
        const index = this.eventDivs.findIndex(el => el.id === eventDetails.id);
        return this.eventDivs[index].html
      }
    },
  },
  mounted() { },
  created() {
    console.log("resources in created:", this.resourcesLocal);
  },
  updated() {
    console.log("resources in updated:", this.resourcesLocal);
  },
  computed: {
    months() {
      const monthsObj = {};

      const addDateToMonth = (date) => {
        const month = moment(date).format("MMMM YYYY");
        const formattedDate = moment(date);
        if (monthsObj[month]) {
          monthsObj[month].push(formattedDate);
        } else {
          monthsObj[month] = [formattedDate];
        }
      };

      // Insert dates before selectedDate into months array
      for (let i = this.previousDatesCount; i >= 1; i--) {
        const date = moment(this.selectedDate).subtract(i, "days");
        addDateToMonth(date);
      }

      // Insert selectedDate into months array
      const date = moment(this.selectedDate);
      addDateToMonth(date);

      // Insert dates after selectedDate into months array
      for (let i = 1; i <= this.upcomingDatesCount; i++) {
        const date = moment(this.selectedDate).add(i, "days");
        addDateToMonth(date);
      }

      let formattedMonths = [];
      for (let month in monthsObj) {
        formattedMonths.push({
          name: month,
          dates: monthsObj[month],
        });
      }

      return formattedMonths;
    },

    eventDivs() {
      const renderableEventDivs = []
      this.events.map(event => {
        const startDate = moment(event.start)
        const endDate = moment(event.end)
        const hourDifference = endDate.diff(startDate, 'hours')

        const eventWidth = (this.cellWidth / 24) * (hourDifference - 1);
        let div = {
          id: event.id, html: `
          <div class="start-here" style="width: ${eventWidth}px; height: ${this.rowHeight}px; position: absolute; background: tranparent; z-index: 1; pointer-events: none; ">
            <p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-align: center; font-size: 10px; margin: 0; line-height: 30px; color: ${this.styles?.eventTextColor ?? '#383636'}"> ${event.text ?? ''} </p>
          </div>
        `};
        renderableEventDivs.push(div)
      })
      return renderableEventDivs
    }
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
body {
  font-family: "Montserrat", sans-serif;
}

.booking-calendar-panel {
  padding: 0;
  margin: 0;
}

.input-font {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.modal-header {
  padding: 5px 20px;
}

.footer-modal {
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-top: 10px;
}

.footer-modal button {
  min-width: 100px;
}

.form-control {
  font-size: 14px;
}

.modal-header h1 {
  font-size: 16px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.modal-body label {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.booking-calendar-heading {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-right: 10px;
}

.filter-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-nav {
  background: none;
  border: none;
  cursor: pointer;
}

.btn-close {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 11px;
}

.fade {
  background: #0000004f;
}

.btn-nav svg {
  fill: #018081 !important;
}

.input-date {
  color: #979797;
  border-top: none;
  border-left: 0px;
  border-right: 0px;
  border-bottom: solid 1px #979797;
  border-radius: 0px;
  padding: 5px 10px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.btn-reservation {
  color: #018081;
  background: none;
  border: solid 1px #018081;
  border-radius: 5px;
  height: 30px;
  cursor: pointer;
  padding: 6px 10px;
  font-family: "Montserrat", sans-serif;
  line-height: 0px;
}

.booking-calendar-body {
  margin: 1rem 0;
  border: solid 1px #ededed;
  max-width: 100vw;
  max-height: 88vh;
  overflow: overlay;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.booking-calendar-body::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

.items-container {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.btn-expand {
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 14px !important;
}

.calendar-row {
  display: flex;
  /* height: 30px; */
}

.calendar-row.rowspan-2 {
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 4;
}

.calendar-row.rowspan-2 .calendar-cell.label {
  height: 100%;
}

.calendar-row.rowspan-2 .calendar-cell.date {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background: #eeeeee;
  /* box-shadow: 0 0 4px 2px #0180810f; */
  border-bottom: 0.5px solid #c0c0c059;
  border-top: 0.5px solid #c0c0c059;
}

.calendar-row.rowspan-2 .caption {
  height: 50%;
}

.calendar-row.rowspan-2 .dates {
  height: 50%;
}

.calendar-row .caption {
  text-align: center;
  box-shadow: 0 0 0 1px #ededed;
  box-sizing: border-box;
  background: #ffffff;
  padding: 5px;
  font-weight: 600;
}

.calendar-row .dates {
  display: flex;
  height: 100%;
}

.calendar-row .calendar-cell {
  box-shadow: 0 0 0 1px #f4f5f7;
  /* min-width: 80px;
  width: calc(100vw / 16); */
  height: 100%;
  box-sizing: border-box;
  background: #ffffff;
  padding: 5px;
  font-size: 12px;
}

.calendar-row .calendar-cell.label {
  box-shadow: 0 0 0 1px #dadada;
  background: #eeeeee;
  min-width: 250px;
  max-width: 250px;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: sticky;
  font-family: "Montserrat", sans-serif;
  left: 0;
  z-index: 3;
}

.childs-container .calendar-cell.label {
  padding-left: 30px;
}

.calendar-cell {
  display: flex;
}

.hour {
  position: relative;
  flex: 1;
  height: 100%;
  width: calc(100% / 24);
}

.calendar-cell.date:not(.label) {
  padding: 0;
}

.calendar-cell.date.selected {
  background: #018081 !important;
  color: #ffffff;
  box-shadow: 0 0 0 1px #919191;
}

.calendar-cell.date.current {
  background: #bddddd !important;
  color: #ffffff;
}

.item-group.expanded svg {
  transform: rotate(90deg);
}

.months {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.bg-btn-radius {
  background: whitesmoke;
  margin: 0px 6px;
  border-radius: 10px;
  width: 33px;
  height: 33px;
  text-align: center;
  line-height: 10px;
}

.calendar-cell.non-selectable {
  background: #eeeeee !important;
  cursor: not-allowed;
  box-shadow: 0 0 0 1px #eeeeee;
}

.hour.booked-hour {
  background-color: #dce4ef;
}

.resizable {
  cursor: ew-resize;
  background-color: #018081 !important
}

.save-event-btn {
  background: #018081 !important;
  color: white;
}

.hour .btn-delete-event {
  border: 0px;
  position: absolute;
  padding: 0px;
  right: 7px;
  top: -7px;
  height: 100%;
  width: auto;
  z-index: 2;
  background: transparent;
  font-size: 11px;
  font-weight: 500;
}

.sat,
.sun {
  background-color: #f9f9f9 !important;
}

.blocked-cell {
  background-color: #ccc !important;
}

.blocked-selection-btn {
  visibility: hidden;
  background-color: red;
  display: inline-block;
  color: white;
  border-radius: 50%;
  width: 1.2rem;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 4px 4px;
  text-align: center;
}
</style>
