<template>
  <svg :class="direction" fill="#000000" :height="size" viewBox="0 0 24 24">
    <polygon points="6.8,23.7 5.4,22.3 15.7,12 5.4,1.7 6.8,0.3 18.5,12" />
  </svg>
</template>

<script>
export default {
  name: 'ArrowIcon',
  props: {
    direction: String,
    size: String
  }
}
</script>

<style scoped>
svg.left {
  transform: rotate(180deg);
}

svg.right {
  transform: rotate(0deg);
}
</style>