<template>
  <BookingCalendar :resources="resources" :events="events" :offDates="offDates" :styles="styles"
    @update-event="updateEvent" @create-event="createEvent" @delete-event="deleteEvent" @event-clicked="onEventClick" />
</template>

<script>
import BookingCalendar from "./components/BookingCalendar.vue";

export default {
  name: "App",
  components: {
    BookingCalendar,
  },
  data() {
    return {
      resources: [
        {
          id: 1,
          name: "Luxury Tentend Suite",
          expanded: true,
          children: [
            {
              id: 1,
              name: "Luxury Tentend Suite 1",
            },
            {
              id: 2,
              name: "Luxury Tentend Suite 2",
            },
            {
              id: 3,
              name: "Luxury Tentend Suite 3",
            },
          ],
        },
        {
          id: 2,
          name: "Luxury Tentend Family Suite",
          expanded: true,
          children: [
            {
              id: 11,
              name: "Luxury Tentend Family Suite 1",
            },
            {
              id: 12,
              name: "Luxury Tentend Family Suite 2",
            },
            {
              id: 13,
              name: "Luxury Tentend Family Suite 3",
            },
            {
              id: 14,
              name: "Finch Hattons Suite 4",
            },
            {
              id: 15,
              name: "Finch Hattons Suite 5",
            },
            {
              id: 16,
              name: "Finch Hattons Suite 6",
            },
            {
              id: 17,
              name: "Finch Hattons Suite 7",
            },
          ],
        },
        {
          id: 3,
          name: "Finch Hattons Suite",
          expanded: false,
          children: [
            {
              id: 21,
              name: "Finch Hattons Suite 1",
            },
            {
              id: 22,
              name: "Finch Hattons Suite 2",
            },
            {
              id: 23,
              name: "Finch Hattons Suite 3",
            },
            {
              id: 24,
              name: "Finch Hattons Suite 4",
            },
            {
              id: 25,
              name: "Finch Hattons Suite 5",
            },
            {
              id: 26,
              name: "Finch Hattons Suite 6",
            },
            {
              id: 27,
              name: "Finch Hattons Suite 7",
            },
          ],
        },
      ],
      events: [
        {
          id: 1,
          resource: {
            id: 11,
            name: "Luxury Tentend Family Suite 1",
          },
          start: "2024-07-08 12:00",
          end: "2024-07-10 11:59",
          text: 'Event 1'
        },
        {
          id: 2,
          resource: {
            id: 1,
            name: "Luxury Tentend Suite 1",
          },
          start: "2024-07-10 12:00",
          end: "2024-07-13 11:59",
          text: 'Event 1'
        },
      ],
      offDates: {
        global: [{ start_date: "2024-07-22", end_date: "2024-07-23" }, { start_date: "2024-08-29", end_date: "2024-08-31" }],   // Dates unavailable for all accommodations
        resources: {
          "1": [{ start_date: "2024-07-20", end_date: "2024-07-20" }, { start_date: "2024-07-22", end_date: "2024-07-23" }],
          "11": [{ start_date: "2024-07-24", end_date: "2024-07-26" }] // Specific accommodations blocked for these dates
        }
      },
      styles: {
        cellWidth: 80,
        rowHeight: 30,
        cellBackground: '#ffffff',
        selectedDateBackground: '#018081 !important',
        currentDateBackground: '#bddddd !important',
        currentDateColor: '#ffffff',
        draggingCellBackground: '#b3d4fc',
        eventBackground: '#dce4ef',
        eventStartBackground: '#018081',
        eventEndBackground: '#018081',
        eventTextColor: '#383636',
        resourceLabelParentStyles: {
          background: '#eeeeee !important',
          fontSize: '14px !important'
        },
        resourceLabelChildStyles: {
          background: '#eeeeee',
        }
      },
    };
  },
  methods: {
    updateEvent(event) {
      const eventIndex = this.events.findIndex((e) => e.id === event.id);
      if (eventIndex !== -1) {
        this.events[eventIndex] = event;
      }
    },
    createEvent(event) {
      const newEvent = {
        id: this.events.length + 1,
        resource: event.resource,
        start: event.start,
        end: event.end,
        text: event.text
      }
      this.events.push(newEvent)
      console.log('this.events after createEvent', this.events)
    },
    deleteEvent(event) {
      const eventIndex = this.events.findIndex((e) => e.id === event.id)
      if (eventIndex !== -1) {
        this.events.splice(eventIndex, 1)
      }
      // window.alert('deleted')
    },
    onEventClick(event) {
      // console.log('event', event)
      window.alert(`This is booked event for Room ${event.resource.name} from ${event.start} to ${event.end}`)
    },
  }
};
</script>
